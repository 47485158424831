.wrapper {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("../images/Map/banner.jpg");
  background-size: cover;
  background-position: center;
  padding: 72px 0px;
  height: 500px;
  width: 100%;
}

.section {
  padding: 32px;
  width: 100%;
}

.map_banner_content {
  width: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 40px 20px 40px 120px;
}

.map_banner_content h1 {
  color: white;
}
.map_banner_content p {
  color: white;
}

.info-window {
  font-family: Arial, sans-serif;
  max-width: 250px;
  color: #333;
}

.info-window-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 8px;
}

.info-window-address {
  font-size: 14px;
  margin-bottom: 8px;
  color: #555;
}

.info-window-exhibit {
  display: inline-block;
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  font-size: 12px;
  border-radius: 4px;
}

.notion {
  display: flex;
  align-items: center;
  gap: 64px;
  padding-top: 24px;
  padding-bottom: 24px;
}

.notion_item {
  display: flex;
  align-items: center;
  gap: 12px;
}

.notion_item img {
  height: 24px;
}

/* Responsive styles for mobile devices */
@media (max-width: 768px) {
  .map-container {
    height: 100vh; /* Adjust height for mobile view */
  }

  .info-window {
    max-width: 200px; /* Reduce the max width on smaller screens */
  }

  .info-window-title {
    font-size: 16px; /* Adjust font size for smaller screens */
  }

  .info-window-address {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .info-window-exhibit {
    font-size: 10px; /* Adjust font size for smaller screens */
    padding: 4px 8px; /* Adjust padding for smaller screens */
  }

  .wrapper {
    padding: 16px;
  }

  .section {
    padding: 8px;
  }

  .map_banner_content h1 {
    font-size: 16px;
  }
  .map_banner_content p {
    font-size: 16px;
  }

  .map_banner_content {
    width: 100%;
    padding: 16px;
    top: 90%;
  }
}

@media (max-width: 480px) {
  .map-container {
    height: 100vh; /* Full viewport height on very small screens */
  }

  .info-window {
    max-width: 180px; /* Further reduce the max width for very small screens */
  }

  .info-window-title {
    font-size: 14px; /* Adjust font size further */
  }

  .info-window-address {
    font-size: 11px; /* Adjust font size further */
  }

  .info-window-exhibit {
    font-size: 9px; /* Adjust font size further */
    padding: 3px 6px; /* Adjust padding further */
  }

  .wrapper {
    padding: 16px;
  }

  .section {
    padding: 2px;
  }
  .map_banner_content h1 {
    font-size: 16px;
  }
  .map_banner_content p {
    font-size: 16px;
  }

  .map_banner_content {
    width: 100%;
    padding: 16px;
    top: 90%;
  }
}


.contactInfo {
  margin-top: 20px;
  padding: 15px;
  background-color: #f4f6f8; /* Light grey background */
  border-radius: 8px; /* Rounded corners */
  text-align: center; /* Center align the text */
  font-size: 1.2rem; /* Slightly larger text */
  color: #333; /* Dark grey text color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

.contactInfo p {
  margin: 0; /* Remove default margin */
  line-height: 1.5; /* Improve text readability */
}

.contactInfo {
  margin-top: 20px;
  padding: 15px;
  background-color: #f4f6f8; /* Light grey background */
  border-radius: 8px; /* Rounded corners */
  text-align: center; /* Center align the text */
  font-size: 1.2rem; /* Slightly larger text */
  color: #333; /* Dark grey text color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

.contactInfo p {
  margin: 0; /* Remove default margin */
  line-height: 1.5; /* Improve text readability */
}

.contactInfo {
  margin-top: 20px;
  padding: 15px;
  background-color: #f4f6f8; /* Light grey background */
  border-radius: 8px; /* Rounded corners */
  text-align: center; /* Center align the text */
  font-size: 1.2rem; /* Slightly larger text */
  color: #333; /* Dark grey text color */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
}

.contactInfo p {
  margin: 0; /* Remove default margin */
  line-height: 1.5; /* Improve text readability */
}

.hotlineLink {
  color: #4caf50; /* Primary color */
  text-decoration: none; /* Remove underline */
  font-weight: bold; /* Make the hotline link bold */
  transition: color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition */
  position: relative; /* For underline animation */
  display: inline-block; /* Ensures link behaves as inline block for animations */
}

.hotlineLink::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 2px;
  background-color: #4caf50; /* Primary color underline */
  transform: scaleX(0);
  transform-origin: right;
  transition: transform 0.3s ease;
}

.hotlineLink:hover {
  color: #388e3c; /* Darker shade of primary color on hover */
  transform: scale(1.08); /* Slightly enlarge on hover */
  box-shadow: 0 0 8px #4caf50, 0 0 12px #4caf50; /* Add a glowing effect */
}

.hotlineLink:hover::after {
  transform: scaleX(1); /* Animate underline on hover */
  transform-origin: left;
}

.hotlineLink::before {
  content: '';
  position: absolute;
  top: -3px;
  left: 0;
  right: 0;
  bottom: -3px;
  border-radius: 5px;
  box-shadow: 0 0 8px rgba(76, 175, 80, 0.5);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.hotlineLink:hover::before {
  opacity: 1; /* Show the glowing border on hover */
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 8px #4caf50, 0 0 12px #4caf50;
  }
  50% {
    box-shadow: 0 0 16px #4caf50, 0 0 24px #4caf50;
  }
  100% {
    box-shadow: 0 0 8px #4caf50, 0 0 12px #4caf50;
  }
}

.hotlineLink:hover {
  animation: pulse 1s infinite; /* Pulse animation on hover */
}
