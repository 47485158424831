@tailwind base;
@tailwind components;
@tailwind utilities;

.slick-dots li.slick-active button:before {
  color: #306b1b !important;
}

.slick-dots li button:before {
  color: #306b1b !important;
  font-size: 12px !important;
}
