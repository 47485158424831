/* chatbot.module.css */
.chatbotContainer {
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
  animation: float 2s infinite ease-in-out; /* Bobbing effect */
}

.logo {
  width: 128px; /* Larger mascot */
  height: 128px;
  margin-bottom: -5px;
}

.label {
  font-size: 14px; /* Smaller font size */
  font-weight: bold;
  color: #4CAF50; /* Green text color */
  text-align: center;
}

/* Floating (bobbing) effect */
@keyframes float {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-8px);
  }
}

@media only screen and (max-width: 767px) {
  .chatbotContainer {
    bottom: 1.25rem;
    right: 1.25rem;
  }

  .logo {
    width: 100px; /* Larger mascot */
    height: 100px;
  }
}
