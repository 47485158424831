
.section {
  width: 100%;
}
.section h1 {
  color: #306b1b;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.section p {
  color: #1a1c18;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.navigation {
  margin-top: 12px;
  margin-bottom: 72px;
  color: #1a1c18;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  display: flex;
  justify-content: center;
}
.tab {
  border-radius: 24px;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  padding: 16px 22px;
  cursor: pointer;
  color: #306b1b;
}
.navigation .active {
  color: #1a1c18;
  background: #d8e7cb;
}

.section .order_button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 28px;
  background: #306b1b;
  color: white;
  font-size: 2rem;
}

@media only screen and (max-width: 767px) {
  .section {
    padding: 12px 16px;
  }
  .section h1 {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .section .order_button {
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 1023px) {
  .section {
    padding: 12px 16px;
  }
}

@media screen and (max-width: 767px) {
  .section {
    padding: 16px;
  }
}
@media screen and (max-width: 1023px) {
  .section {
    padding: 16px;
  }
}

@keyframes discountAnimation {
  0%, 100% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.2);
  }
}


.highlightContainer {
  background-color: #ffffff; /* White background for contrast */
  padding: 15px 25px;
  border-radius: 8px;
  text-align: center;
  display: block;
  color: #4A833E; /* Primary color for the subtitle */
  max-width: 600px;
  margin: 0 auto; /* Center the block horizontally */
}

.highlightTitle {
  font-weight: bold;
  font-size: 1.5em;
  margin: 0;
  background: linear-gradient(90deg, #4A833E, #8BC34A);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: discountAnimation 1.5s infinite ease-in-out;
  animation: discountAnimation 1.5s infinite ease-in-out;
}

.highlightSubtitle {
  font-size: 1.2em;
  margin-top: 5px;
  margin-bottom: 0;
  color: #1a1c18; /* Subtitle color */
}