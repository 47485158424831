.container {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 19.27%,
      rgba(0, 0, 0, 0.04) 100%
    ),
    url("../images/Contact/bg_contact.jpeg"),
    lightgray 0px -201px / 100% 171.45% no-repeat;
  background-size: cover;
  background-position: center;
  padding: 50px 120px;
}
.container h1 {
  color: #fff;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
}
.container p {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  width: 85%;
}
.container_form {
  width: 100%;
  background: rgba(253, 253, 246, 0.8);
  padding: 32px;
}
.container_form button {
  width: 200px;
  padding: 12px 0px;
  border-radius: 12px;
  background: #306b1b;
  color: #fff;
}
.container_form button:disabled {
  background: grey;
}
.form {
  margin-bottom: 16px;
}
.form .label {
  color: #1a1c18;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.form span {
  color: #de3730;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.form input {
  outline: none;
  width: 100%;
  height: 48px;
  padding: 0px 16px;
  border-radius: 12px;
  border: 1px solid #e3e3dc;
  background: #fff;
}
.form textarea {
  border-radius: 12px;
  border: 1px solid #e3e3dc;
  width: 100%;
  outline: none;
  padding: 9px 16px;
  resize: none;
}

@media only screen and (max-width: 639px) {
  .container {
    padding: 0 16px;
  }
  .container h1 {
    text-align: center;
    font-size: 22px;
  }
  .container p {
    width: 100%;
    text-align: center;
    margin-bottom: 16px;
    font-size: 14px;
  }
}
@media only screen and (max-width: 1023px) {
  .container {
    padding: 40px 16px;
  }
  .container h1 {
    text-align: center;
    font-size: 22px;
  }
}
