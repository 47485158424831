.wrapper_bg {
  background-image: linear-gradient(rgba(12, 57, 0, 0) 0%, #0c3900 77.08%),
    url("../images/Home/bg_banner_small.jpeg");
  background-size: cover;
  background-position: center;
  padding: 72px 120px 0 120px;
}
.wrapper {
  width: 100%;
  min-height: 628px;
  margin-top: 52px;
}
.wrapper .big_title {
  color: #fff;
  font-family: Be Vietnam Pro;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  line-height: 72px;
}
.wrapper h1 {
  color: #fff;
}
.wrapper article {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 85%;
  margin-top: 24px;
}
.img_decor {
  width: 352px;
  height: 352px;
  border-radius: 9999px;
  border: 20px solid rgba(255, 255, 255, 0.15);
  overflow: hidden;
  flex-shrink: 0;
}
.elipse_small {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
}
.elipse_medium {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
}
.elipse_big {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 767px) {
  .wrapper article {
    width: 100%;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-top: 0px;
  }
  .wrapper h1 {
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .wrapper .big_title {
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
@media only screen and (max-width: 1279px) {
  .wrapper_bg {
    padding: 72px 32px 0 32px;
  }
}
@media only screen and (max-width: 1023px) {
  .wrapper_bg {
    padding: 72px 16px 0 16px;
  }
}
