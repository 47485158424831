@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

iframe {
  font-family: "Be Vietnam Pro";
}

.wrapper {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("../images/Knowledge/bg_product_small.png");
  background-size: cover;
  background-position: center;
  padding: 72px 0px;
  height: 500px;
  width: 100%;
}
.wrapper_mini {
  position: relative;
  border-radius: 20px;
  border: 1px solid #e3e3dc;
  padding: 24px;
}
.knowledge_banner_content {
  width: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 40px 20px 40px 120px;
}
.knowledge_banner_content h1 {
  color: white;
}
.knowledge_banner_content p {
  color: white;
}
.title_mini_news {
  color: #1a1c18;
  font-family: Be Vietnam Pro;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.post {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.post .title {
  color: #1a1c18;
  font-family: Be Vietnam Pro;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
.post .content {
  color: #1a1c18;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3; 
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.post .date {
  color: #5d5f5a;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.section {
  padding: 32px 120px;
  width: 100%;
}
.section h1 {
  color: #306b1b;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
.section p {
  color: #1a1c18;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.btn {
  margin-top: 12px;
  display: flex;
  width: 100%;
  height: 40px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid #c6c7c1;
}
.navigation {
  margin-top: 12px;
  margin-bottom: 72px;
  color: #1a1c18;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.navigation .active {
  padding: 12px 16px;
  color: #306b1b;
  border-bottom: 2px solid #306b1b;
}
.read_more a {
  margin-top: 12px;
  color: #0066d4;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
}
.iframe {
  padding: 20px 120px;
}
.loading_message {
  height: 400px;
  margin-top: 100px;
  text-align: center;
}

@media screen and (max-width: 767px) {
  .section {
    padding: 16px;
  }
  .wrapper_mini {
    padding: 24px 0 0 0;
    border: none;
  }
  .knowledge_banner_content {
    width: 90%;
  }
  .section p {
    font-size: 14px;
  }
  .post .title {
    font-size: 16px;
  }
  .post .content {
    font-size: 14px;
  }
  .post .date {
    font-size: 14px;
  }
  .wrapper {
    height: 200px;
  }
  .iframe {
    padding: 20px;
  }
  
}
@media screen and (max-width: 1023px) {
  .section {
    padding: 16px;
  }
}
