@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

* {
  box-sizing: border-box;
  font-family: "Be Vietnam Pro";
  padding: 0;
  margin: 0;
}
iframe {
  font-family: "Be Vietnam Pro";
}
h1 {
  color: #1a1c18;
  font-size: 38px;
  font-style: normal;
  font-weight: 600;
  line-height: 56px;
}
h2 {
  color: #1a1c18;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 32px;
}
h3 {
  color: #1a1c18;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}
p {
  color: #1a1c18;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
@media only screen and (max-width: 767px) {
  h3 {
    font-size: 16px;
  }
  p {
    font-size: 14px;
  }
}

.a-16-9 {
  aspect-ratio: 16/9;
  overflow: hidden;
}

.row-3-ellipsis {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.leaflet-div-icon {
  display: block !important; /* Ensure it is visible */
  background: none; /* Remove any background if it's interfering */
  border: none; /* Remove any border if it's interfering */
}