/* qrcode.module.css */
.qrPageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f4;
}

.qrContainer {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.qrInput {
  padding: 10px;
  width: calc(100% - 20px);
  font-size: 16px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

.qrCode {
  margin: 20px auto;
  position: relative;
  display: inline-block;
}

.downloadButton {
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.downloadButton:hover {
  background-color: #45a049;
}

@media (max-width: 500px) {
  .qrContainer {
    padding: 15px;
  }

  .title {
    font-size: 20px;
  }

  .qrInput {
    font-size: 14px;
  }

  .downloadButton {
    padding: 8px 16px;
    font-size: 14px;
  }
}
