.wrapper {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("../images/Product/banner.jpg");
  background-size: cover;
  background-position: center;
  padding: 72px 0px;
  height: 500px;
  width: 100%;
}
.order_button {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 28px;
  background: #306b1b;
  color: white;
  font-size: 2rem;
}

.prouduct_banner_content {
  width: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 40px 20px 40px 120px;
}
.prouduct_banner_content h1 {
  color: white;
}
.prouduct_banner_content p {
  color: white;
}
.section {
  padding: 72px 120px;
  width: 100%;
}
.section h1 {
  color: #306b1b;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.section p {
  color: #1a1c18;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.product_usp {
  color: #306b1b;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
@media screen and (max-width: 767px) {
  .section {
    padding: 16px;
  }
  .section h1 {
    font-size: 28px;
  }
  .prouduct_banner_content {
    width: 90%;
  }
}
@media screen and (max-width: 1023px) {
  .section {
    padding: 16px;
  }
  .section h1 {
    font-size: 28px;
  }
  .order_button {
    font-size: 1.5rem;
  }
  @media only screen and (max-width: 767px) {
    .prouduct_banner_content {
      width: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 40px;
    }
    .order_button {
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 1023px) {
    .prouduct_banner_content {
      width: 90%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 40px;
    }
  }
}
