.product_usp {
  color: #306b1b;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
}

@media only screen and (max-width: 767px) {
  .product_usp {
    color: #306b1b;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
