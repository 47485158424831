.loading {
  filter: blur(10px);
  clip-path: inset(0);
}
.loaded {
  filter: blur(0px);
  transition: filter 0.5s linear;
}
.blur_load {
  width: 100%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
}
.blur_load::before {
  content: "";
  position: absolute;
  inset: 0;
  animation: pulse 2.5s infinite;
  background-color: rgba(255, 255, 255, 0.1);
}

@keyframes pulse {
  0% {
    background-color: rgba(255, 255, 255, 0);
  }
  50% {
    background-color: rgba(255, 255, 255, 0.1);
  }
  100% {
    background-color: rgba(255, 255, 255, 0);
  }
}
