.wrapper {
  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.6) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    url("../images/About/bg_banner_about.png");
  background-size: cover;
  background-position: center;
  padding: 72px 0px;
  height: 724px;
  position: relative;
  width: 100%;
}
.content_about_us {
  width: 33%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 40px 20px 40px 120px;
}
.content_about_us h1 {
  color: white;
}
.content_about_us p {
  color: white;
}
.img_decor {
  width: 352px;
  height: 352px;
  border-radius: 9999px;
  border: 20px solid rgba(255, 255, 255, 0.15);
  overflow: hidden;
}
.circle_custom {
  display: flex;
  width: 176px;
  height: 176px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  border: 4px solid rgba(255, 255, 255, 0.6);
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
/* vision */
.content_vision {
  padding: 12px;
}
/* flip card */
.container {
  width: 100%;
  height: 100%;
}
.container:hover > .card {
  cursor: pointer;
  /* transform: rotateY(180deg); */
}
.card {
  height: 100%;
  width: 100%;
  position: relative;
  transition: transform 1000ms;
  transform-style: preserve-3d;
}
.front,
.back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
}
.front h6 {
  color: #fff;
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.back {
  background-color: #eef3e5;
  /* transform: rotateY(180deg); */
  border-radius: 16px;
  padding: 28px;
}
.back p {
  color: #0c3900;
  font-family: Be Vietnam Pro;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
.back h6 {
  color: #0c3900;
  font-family: Be Vietnam Pro;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
@media only screen and (max-width: 767px) {
  .content_about_us {
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
  }
  .wrapper {
    height: 500px;
  }
}
@media only screen and (max-width: 1023px) {
  .content_about_us {
    width: 90%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px;
  }
  .wrapper {
    height: 500px;
  }
}
