/* form.module.css */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.openModalBtn {
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 28px;
  border: 2px solid #306b1b;
  color: #306b1b;
  font-size: 2rem;
}

.openModalBtn:hover {
  border: 2px solid #45a049;
  color: #45a049;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8); /* Semi-transparent black for overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background: white;
  padding: 30px;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  font-size: 24px;
  color: #333;
  cursor: pointer;
}

.modalTitle {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.form {
  display: flex;
  flex-direction: column;
}

.formItem {
  margin-bottom: 15px;
}

.formItem label {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
  display: block;
}

.formItem input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  transition: border-color 0.3s;
}

.formItem input:focus {
  border-color: #4caf50; /* Green border on focus */
  outline: none;
}

.submitBtn {
  display: flex;
  padding: 6px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 12px;
  background: #306b1b;
  color: white;
  font-size: 1.25rem;
}

.submitBtn:hover {
  background-color: #45a049;
}


@media only screen and (max-width: 767px) {
  .openModalBtn {
    font-size: 1.2rem;
    padding: 10px 16px;
  }
}
